import "./styles.scss"

import React from "react"
import { motion } from "framer-motion"

import ProductBuy from "components/ProductBuy"

import fadeAnimation from "utils/fadeAnimation"

const ContentSingle = ({ data, setPopup }) => {
  return (
    <motion.section className="glue-single" {...fadeAnimation}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <div className="glue-single__image">
              <img
                src={data?.featuredImage?.node?.localFile?.publicURL}
                alt=""
              />
            </div>
          </div>

          <div className="col-lg-6">
            <div className="glue-single__inner">
              <div>
                <h2>{data?.name}</h2>
                <h4>Informacje</h4>
                <div
                  className="glue-single__inner-description"
                  dangerouslySetInnerHTML={{ __html: data?.content }}
                />
              </div>

              <ProductBuy
                id={data?.id}
                databaseId={data?.databaseId}
                name={data?.name}
                price={data?.price}
                setPopup={setPopup}
                product={data}
                sample
              />
            </div>
          </div>

          <div className="col-md-6">
            {data?.acfProduct?.gluesAdditionalInfo && (
              <div className="glue-single__info">
                <h4>Informacje dodatkowe</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.acfProduct?.gluesAdditionalInfo,
                  }}
                />
              </div>
            )}
          </div>

          <div className="col-md-6">
            <div className="glue-single__specification">
              <h4>Specyfikacja</h4>
              {data?.acfProduct?.gluesSpecification?.map((item, index) => (
                <div className="glue-single__specification-item" key={index}>
                  <strong>{item.label}</strong>
                  <p>{item.value}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </motion.section>
  )
}

export default ContentSingle
