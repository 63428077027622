import "./styles.scss"

import React from "react"
import { motion } from "framer-motion"

import ContactForm from "components/ContactForm"

import modalAnimation from "utils/modalAnimation"

const ProductPopup = ({ popup, setPopup }) => {
  return (
    <motion.div className="product-popup" {...modalAnimation.outer}>
      <motion.div className="product-popup__wrapper" {...modalAnimation.inner}>
        <button
          className="product-popup__close"
          onClick={() => setPopup(false)}
        >
          Close
        </button>
        <h3>Zapytanie o produkt</h3>
        <h4>{popup}</h4>
        <ContactForm />
      </motion.div>
    </motion.div>
  )
}

export default ProductPopup
