import "./styles.scss"

import React from "react"
import { motion } from "framer-motion"

import ProductTile from "components/ProductTile"

import { useProducts } from "context/products"

import fadeAnimation from "utils/fadeAnimation"

const AnimatedProductTile = motion(ProductTile)

const Content = ({ pageData }) => {
  const { products } = useProducts()

  return (
    <section className="glues-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-7 offset-xl-1">
            <motion.div
              className="glues-content__description h5"
              dangerouslySetInnerHTML={{ __html: pageData?.content }}
              {...fadeAnimation}
            />
          </div>
        </div>

        <div className="row">
          {products
            ?.filter(item =>
              item.productCategories.nodes.some(
                node => node.slug === "kleje-do-tapet"
              )
            )
            .map((item, index) => (
              <div className="col-lg-4 col-sm-6" key={index}>
                <AnimatedProductTile item={item} {...fadeAnimation} />
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}

export default Content
